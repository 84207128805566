<template>
  <el-container class="main">
    <el-row
      style="
        width: 100%;
        height: auto;
      "
      type="flex"
      justify="center"
    >
      <el-col
        :xl="5"
        :lg="8"
        :md="10"
        :sm="12"
        :xs="18"
      >
        <el-row
          type="flex"
          justify="center"
          style="width: 100%"
        >
          <el-col class="avatar-wrapper">
            <input
              id="fileInput"
              ref="fileInp"
              type="file"
              style="display: none;"
              v-on:change="setImage">
            <div class="component" @click="openfile">
              <Avatar
                :src="avatar"
                :size="150"
                fit="cover"
                style="cursor: pointer;"
              />
              <div id="overflow-avatar">
                Cambiar foto de perfil
              </div>
            </div>
          </el-col>
        </el-row>
        <el-form
          :model="form"
          :rules="rules"
          ref="form"
          @keyup.enter.native="submit"
        >
          <el-form-item prop="firstName">
            <el-input
              prefix-icon="el-icon-user"
              v-model="form.firstName"
              placeholder="Nombre"
              maxlength="100"
            />
          </el-form-item>
          <el-form-item prop="lastName">
            <el-input
              prefix-icon="el-icon-user"
              v-model="form.lastName"
              placeholder="Apellido"
              maxlength="100"
            />
          </el-form-item>
          <el-form-item prop="birthdate">
            <el-date-picker
              v-model="form.birthdate"
              type="date"
              placeholder="Fecha de nacimiento"
              style="width: 100%"
              maxlength="10"
            />
          </el-form-item>
          <el-form-item prop="email">
            <el-input
              prefix-icon="el-icon-user"
              v-model="form.email"
              placeholder="Correo eletrónico"
              maxlength="255"
            />
          </el-form-item>
          <el-form-item prop="phoneNumber">
            <el-input
              prefix-icon="el-icon-phone-outline"
              v-model="form.phoneNumber"
              placeholder="Número de teléfono"
              maxlength="12"
            />
          </el-form-item>
          <div class="cedulaInfo" v-if="form.id === 'MD'">
            <h3>Cédula profesional</h3>
            <div
              class="addCedula"
              style="border-radius: 20px; width: 100%; margin-bottom: 15px;"
            >
              <el-button
                type="primary"
                icon="el-icon-plus"
                size="mini"
                @click="addCedula"
              >Agregar</el-button>
            </div>
            <div class="cedulaTarjeta">
              <el-card v-for="(cedula, idx) in cedula" :key="idx" class="cedulaTarjetaEstruct">
                <label>Universidad</label><br>
                <input type="text" v-model="cedula.university"><br><br>
                <label>Cédula</label><br>
                <input type="text" v-model="cedula.number"> <br><br>
                <div style="display: flex; justify-content: center;">
                  <el-button
                    v-if="cedula.id"
                    type="primary"
                    size="mini"
                    @click="updateCedula(cedula.id, idx)"
                  >Actualizar</el-button>
                  <el-button
                    type="danger"
                    size="mini"
                    @click="deleteCedula(cedula.id, idx)"
                  >Eliminar</el-button>
                </div>
              </el-card>
            </div>
          </div>
          <div class="signature" v-if="form.id === 'MD'">
            <h3>Firma Digital</h3>
            <!-- <signature /> -->
            <svgSignature :userId="form.userId" />
          </div>
          <el-button
            @click="submit"
            type="primary"
            style="width: 100%"
          >
            Actualizar
          </el-button>
        </el-form>
      </el-col>
    </el-row>
  </el-container>
</template>

<script>
import { mapState } from 'vuex';
import { originalURL } from '../API';
import svgSignature from '../components/signature/svgSignature';
// import signature from '../components/signature';

export default {
  data() {
    return {
      baseURL: originalURL,
      avatar: '',
      loading: false,
      loadingService: null,
      contenedorNum: 0,
      contenedor:
        {
          new: [],
          update: [],
          del: [],
        },
      form: {
        id: '',
        userId: '',
        firstName: '',
        lastName: '',
        birthdate: '',
        email: '',
        phoneNumber: null,
      },
      cedula: [],
      rules: {
        firstName: [
          {
            required: true,
            message: 'El campo es requerido',
            trigger: 'blur',
          },
          {
            max: 255,
            message: 'El campo excede el limite de caracteres',
            trigger: 'blur',
          },
        ],
        lastName: [
          {
            required: true,
            message: 'El campo es requerido',
            trigger: 'blur',
          },
          {
            max: 255,
            message: 'El campo excede el limite de caracteres',
            trigger: 'blur',
          },
        ],
        birthdate: [
          {
            required: true,
            message: 'El campo es requerido',
            trigger: 'blur',
          },
        ],
        email: [
          {
            required: true,
            message: 'El campo es requerido',
            trigger: 'blur',
          },
          {
            type: 'email',
            message: 'Correo electrónico no válido.',
            trigger: 'blur',
          },
        ],
        phoneNumber: [
          {
            required: true,
            message: 'Número de teléfono no válido.',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  components: {
    // signature,
    svgSignature,
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    avatarSrc() {
      return this.user.avatar;
    },
  },
  methods: {
    openfile() {
      document.getElementById('fileInput').click();
    },
    addCedula() {
      this.cedula.push({
        contenedorNum: this.contenedorNum,
        university: null,
        number: null,
        userId: this.form.userId,
      });
      this.contenedorNum += 1;
      const ultimoIdx = this.cedula.length - 1;
      this.contenedor.new.push(this.cedula[ultimoIdx]);
    },
    async updateCedula(id, idx) {
      try {
        const response = await this.axios.put(`patient/cedula/updateCedula/${id}`, {
          data: this.cedula[idx],
        });
        if (response) {
          this.$notify.success({
            message: 'Guardado con éxito',
          });
        }
      } catch (error) {
        // error
      }
    },
    async deleteCedula(id, idx) {
      try {
        if (id !== undefined) {
          const response = await this.axios.delete(`patient/cedula/deleteCedula/${id}`, {
            data: this.cedula[idx],
          });
          if (response) {
            this.cedula.splice(idx, 1);
            this.$notify.success({
              message: 'Eliminado con éxito',
            });
          }
        } else {
          const elementoEliminado = this.cedula.splice(idx, 1);
          this.contenedor.new.map((map, index) => {
            if (map.contenedorNum === elementoEliminado[0].contenedorNum) {
              this.contenedor.new.splice(index, 1);
            }
            return '';
          });
        }
      } catch (error) {
        // error
      }
    },
    async getCedula() {
      try {
        const { userId } = await this.form;
        const request = await this.axios.get(`patient/cedula/${userId}`);
        this.cedula = request.data;
      } catch (error) {
        // notr
      }
    },
    async setImage() {
      try {
        this.loading = true;
        const file = this.$refs.fileInp.files;
        if (file.length) {
          const MbLimit = 1;
          const size = file[0].size / 1024 / 1024 < MbLimit;
          const isPNG = file[0].type === 'image/png';
          if (isPNG) {
            if (size) {
              const data = new FormData();
              data.append('file', file[0]);
              const response = await this.axios.patch('user/avatar', data, {
                'content-type': 'application/form-data',
              });
              if (response) {
                this.avatar = null;
                this.$notify({
                  title: '!Éxito!',
                  message: response.data.message,
                });
                this.$store.dispatch('auth/updateUser', {
                  ...this.user,
                  avatar: response.data.image,
                });
              }
            } else {
              this.$message({
                type: 'error',
                message: 'La imagen excede el tamaño limite, por favor use un archivo inferior a 1 Mb.',
                showClose: false,
              });
            }
          } else {
            this.$message({
              type: 'error',
              message: 'Solo se permiten imagenes en formato PNG',
              showClose: false,
            });
          }
        }
        this.loading = false;
      } catch (error) {
        this.$errorNotify(error);
      }
      this.loading = false;
    },
    async submit() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true;
            const {
              firstName,
              lastName,
              email,
              birthdate,
              phoneNumber,
            } = this.form;
            const response = await this.axios.put('user/info', {
              firstName,
              lastName,
              email,
              birthdate,
              phoneNumber,
            });
            if (response) {
              this.$notify({
                type: 'success',
                title: '!Éxito¡',
                message: response.data.message,
              });
              this.$store.dispatch('auth/updateUser', {
                ...this.user,
                firstName,
                lastName,
                email,
                birthdate,
                phoneNumber,
              });
            }
            this.loading = false;
          } catch (error) {
            this.loading = false;
            this.$errorNotify(error);
          }
        }
      });
      if (this.contenedor.new.length > 0) {
        const user = this.form.userId;
        const response2 = this.contenedor.new.map((data) => {
          this.axios.post(`patient/cedula/addCedula/${user}`, {
            data,
          });
          return data;
        });
        if (response2) {
          this.$notify.success({
            message: 'Guardado con éxito',
          });
        }
      }
    },
  },
  mounted() {
    if (this.avatarSrc) {
      this.avatar = `${this.baseURL}/static/user/avatar/${this.avatarSrc}`;
    }
    this.form.userId = this.user.id;
    this.form.id = this.user.role.id;
    this.form.firstName = this.user.firstName;
    this.form.lastName = this.user.lastName;
    this.form.avatar = this.user.avatar;
    this.form.email = this.user.email;
    this.form.birthdate = this.user.birthdate;
    this.form.phoneNumber = this.user.phoneNumber;
  },
  async created() {
    await this.getCedula();
  },
  watch: {
    loading(val) {
      if (val && !this.loadingService) {
        this.loadingService = this.$loading({
          fullscreen: true,
          lock: true,
          text: 'Cargando',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)',
        });
      } else if (this.loadingService) {
        this.loadingService.close();
        this.loadingService = null;
      }
    },
    avatarSrc(val) {
      if (val) {
        this.avatar = `${this.baseURL}/static/user/avatar/${this.avatarSrc}`;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.main {
  background-color: #FFFFFF;
  width: calc(100% - 30px);
  margin-left: 15px;
  margin-top: 15px;
  height: calc(100% - 30px);
  position: relative;
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  overflow-y:auto;
  .avatar-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    .component {
      position: relative;
      overflow: hidden;
      height: 150px;
      width: 150px;
      border: solid 1px #FFFFFF;
      background-color: #CEDCE6;
      border-radius: 125px;
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
      margin: 20px 0px 30px 0px;
      &:hover #overflow-avatar {
        display: flex !important;
      }
      #overflow-avatar {
        top: 50%;
        position: absolute;
        width: 100%;
        height: 40%;
        background-color: rgba(0, 0, 0, 0.4);
        display: none;
        flex-direction: column;
        align-items: center;
        box-shadow: 0px 0px 30px 30px rgba(0, 0, 0, 0.4);
        justify-content: center;
        color:#FFFFFF;
        z-index: 10;
        cursor: pointer;
      }
    }
    .uploader-btn {
      cursor: pointer;
      b {
        text-decoration: underline;
      }
    }
  }
  .cedulaInfo {
    border: 1px solid rgb(224, 224, 224);
    border-radius: 8px;
    padding: 15px;
    .addCedula{
      text-align: right;
      button {
        border: none;
        border-radius: 12px;
      }
    }
    .cedulaTarjeta {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      gap:10px;
      .cedulaTarjetaEstruct {
        padding: 8px;
        border-style: solid;
        border-radius: 12px;
      }
      input:focus {
        border-radius: 5px;
        border-color: #c5c5c5;
        border-style: solid;
      }
      input {
        border-radius: 5px;
        border-color: #c5c5c5;
        border-style: solid;
        border-width: 1px;
        height: 35px;
        padding: 10px;
        color: #4d4d4d;
      }
      label {
        color: #303133;
        margin-top: 8px;
      }
    }
  }
  .signature {
    margin-top: 10px;
    border: 1px solid rgb(224, 224, 224);
    border-radius: 8px;
    padding: 15px;
  }
}
</style>
