<template>
  <div class="signature">
    <div class="signature__container">
      <div class="signature__container__signature">
        <VPerfectSignature
          :stroke-options="strokeOptions"
          ref="signaturePad"
        />
      </div>
      <div class="signature__container__buttons">
        <el-button
          type="primary"
          size="mini"
          plain
          @click="clearSignature"
        >
          Limpiar
        </el-button>
        <el-button
          type="primary"
          size="mini"
          plain
          @click="saveSignature"
        >
          Guardar
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { VPerfectSignature } from 'v-perfect-signature';
import API_SIGNATURE from '../../API/signature';

export default {
  props: {
    userId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      signaturePad: '',
      signatureBase64: '',
      strokeOptions: {
        size: 5,
        thinning: 0.75,
        smoothing: 1,
        streamline: 0.5,
        simulatePressure: false,
      },
    };
  },
  components: {
    VPerfectSignature,
  },
  methods: {
    clearSignature() {
      const signature = this.$refs.signaturePad;
      signature.clear();
    },
    async saveSignature() {
      const signature = this.$refs.signaturePad.toDataURL();
      this.signatureBase64 = signature;
      // eslint-disable-next-line vue/custom-event-name-casing
      this.$emit('saveSignature', signature);

      const request = await this.axios.post('user/signature', {
        signature: this.signatureBase64,
      });

      if (request.status === 200) {
        this.$message({
          message: 'Firma guardada correctamente',
          type: 'success',
        });
      }
    },
    async getSignatureUser() {
      const user = this.userId;
      const request = await API_SIGNATURE.getSigntature(user);
      if (request) {
        this.signatureBase64 = request;
        this.$refs.signaturePad.fromDataURL(this.signatureBase64);
      }
    },
    toDataURL() {
      const signature = this.$refs.signaturePad;
      const dataURL = signature.toDataURL();
    },
  },
  async mounted() {
    await this.getSignatureUser();
  },
};
</script>
